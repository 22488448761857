<template>
    <div class="menuSetting">
        <!-- 菜单设置 -->
        <div class="left">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="名称" prop="text">
                    <el-input v-model="ruleForm.text" size="small"></el-input>
                </el-form-item>
                <el-form-item label="URL" prop="url">
                    <el-input v-model="ruleForm.url" size="small"></el-input>
                    <!-- <span>此文章分类的 URL 地址为：
                      https://www.dasmindfit.com/#/article/category/固定连接</span> -->
                </el-form-item>
                <el-form-item label="父级" prop="name">
                    <el-cascader :options="options" v-model="ruleForm.pid" change-on-select size="small"
                        :props="{ label: 'text', value: 'id', checkStrictly: true, emitPath: false }"
                        :show-all-levels="false"></el-cascader>
                </el-form-item>

                <el-form-item label="排序">
                    <el-input v-model="ruleForm.orderNumber" size="small" type="number"></el-input>
                    <span>越小的数字越靠前，只在同级分类上影响。</span>
                </el-form-item>
                <el-form-item label="标题">
                    <el-input v-model="ruleForm.title" size="small"></el-input>
                    <!-- <span>图标只用于前台显示，是否显示决定于模板。</span> -->
                </el-form-item>

                <el-form-item label="内容">
                    <el-input v-model="ruleForm.content" size="small"></el-input>
                    <span></span>
                </el-form-item>


                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="right">
            <template>
                <el-table row-key="id" border default-expand-all :data="tableData" stripe style="width: 100%">
                    <el-table-column prop="id" label="id">
                    </el-table-column>
                    <el-table-column prop="text" label="文本">
                    </el-table-column>
                    <el-table-column prop="url" label="URL">
                    </el-table-column>
                    <el-table-column prop="orderNumber" label="排序" type="number">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="getMenuDetail(scope.row.id)">编辑</el-button>
                            <el-button type="text" size="small" @click="deleteMenu(scope.row.id)">删除</el-button>
                            <!-- <el-button type="text" size="small">访问</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
                layout="total,  prev, pager, next, jumper" :total="paging.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { menuListAPI, detailMenuAPI, updateMenuAPI, addMenuAPI, deleteMenuAPI } from '@/api/settings/TCCMenu'
export default {
    data() {
        return {
            isEdit: false,
            // 表单信息
            ruleForm: {
                text: null,
                url: null,
                pid: 0,
                orderNumber: null,
                icon: null,
                content: null,
                title: ''
            },
            // 分页
            paging: {
                p: 1,
                ps: 10,
                total: 0,
            },
            rules: {
                text: [
                    { required: true, message: '请输入必填内容', trigger: 'blur' },
                ],
                url: [
                    { required: true, message: '请输入必填内容', trigger: 'blur' },
                ],
                pid: [
                    { required: true, message: '请输入必填内容', trigger: 'blur' },
                ],
            },
            options: [{
                value: 'zhinan',
                label: '指南',
                children: [{
                    value: 'shejiyuanze',
                    label: '设计原则',
                    children: [{
                        value: 'yizhi',
                        label: '一致'
                    }, {
                        value: 'fankui',
                        label: '反馈'
                    }, {
                        value: 'xiaolv',
                        label: '效率'
                    }, {
                        value: 'kekong',
                        label: '可控'
                    }]
                }, {
                    value: 'daohang',
                    label: '导航',
                    children: [{
                        value: 'cexiangdaohang',
                        label: '侧向导航'
                    }, {
                        value: 'dingbudaohang',
                        label: '顶部导航'
                    }]
                }]
            }],
            tableData: []
        }
    },
    methods: {
        // 新增、修改
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let res = {};
                    if (this.isEdit === false) {
                        res = await addMenuAPI(JSON.stringify(this.ruleForm));
                        console.log(res)
                    } else {
                        res = await updateMenuAPI(JSON.stringify(this.ruleForm));
                        console.log(res)
                    }
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            showClose: true,
                            message: '操作成功!'
                        });
                        this.ruleForm = {
                        text: null,
                        url: null,
                        pid: 0,
                        orderNumber: null,
                        icon: null
                    },
                    this.isEdit = false;
                        this.getList();
                    } else {
                        this.$message({
                            showClose: true,
                            message: '操作失败！' + res.msg,
                            type: 'error'
                        });
                    }

                    // alert('submit!');
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 切换分页
        handleCurrentChange(val) {
            this.paging.p = val;
            this.getList();
            //   console.log(`当前页: ${val}`);
        },
        // 获取菜单列表
        async getList() {
            let info = { pageSize: this.paging.ps, pageNum: this.paging.p }
            const res = await menuListAPI(JSON.stringify(info));
            console.log(res);
            if (res.code === 200) {
                this.tableData = res.data.list;
                this.paging.total = res.data.total;
                this.options = [{ id: 0, text: '顶级' }, ...res.data.list]
            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '列表获取失败!'
                });
            }
        },
        // 获取详情
        async getMenuDetail(val) {
            this.isEdit = true;
            const res = await detailMenuAPI({ id: val });
            console.log(res);
            if (res.code === 200) {
                this.ruleForm = res.data;
            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '详情获取失败!'
                });
            }
        },
        // 删除菜单
        deleteMenu(val) {
            this.$confirm('确认删除该分类?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let arr = [val]
                const res = await deleteMenuAPI(arr);
                console.log(res);
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        showClose: true,
                        message: '操作成功!'
                    });
                    
                        this.getList();
                } else {
                    this.$message({
                        showClose: true,
                        message: '操作失败！' + res.msg,
                        type: 'error'
                    });
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });


        }
    },
    created() {
        this.getList();
    }
}
</script>

<style lang="scss" scoped>
.menuSetting {
    display: flex;
    height: 100%;
    justify-content: space-between;

    .left {
        width: 40%;
        padding-right: 40px;
    }

    .right {
        width: 59%;
        // height: 100%;
        min-height: 500px;
        // background: wheat;
    }
}
</style>